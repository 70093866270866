const envDev = 'zion'; // local or qcero
const envUrlData = 'dummy'; // dummy or qcero

const env = {
    local: {
        url: 'http://127.0.0.1:8000/api/v1/',
        defaultColor: 'light.greenmoss',
    },
    zion: {
        url: 'https://agroserv.zionsas.com/api/v1/',
        defaultColor: 'light.greenmoss',
    }
}
export const versionApp = '1.0.0';
//******* URL DATA ****************/
const envData = {
    dummy: {
        url: `${env[envDev].url}dummy`,
    },
    internal: {
        url: `${env[envDev].url}dVkzeFNXYnFpSnF1VmI4/real`,
    }
}
//******* END URL DATA ************/
export const apiUrl = env[envDev].url;
export const urlData = envData[envUrlData].url;
export const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
}
//*****design********* 
export let lightTheme = {
    base: '#93c572',
    primary: '#93c572',
    secondary: '#FFFFFF',
    accent: '#d8d9d4',
    error: '#ff0000',
    foot: '#254945',
    anchor: '#8c9eff',
    textcolor: '#0D0D0D'
};

export let darkTheme = {
    base: '#000000',
    primary: '#000000',
    secondary: '#000000',
    accent: '#BAE310',
    error: '#ff0000',
    foot: '#000000',
    textcolor: '#ffffff',
};
export let defaultDarkTheme = false;
export const elevation = 1;
