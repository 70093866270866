<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    layout: `div`,
  }),
};
</script>

<style>
@font-face {
  font-family: GacorPersonalUse;
  src: local(GacorPersonalUse-Regular),
    url(./assets/fonts/PaytoneOne.ttf);
}

@font-face {
  font-family: Roboto;
  src: local(GacorPersonalUse-Regular),
    url(./assets/fonts/JosefinSans-SemiBold.ttf);
}

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

* {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: var(--v-textcolor-base);
  font-size: 16px;
}


.swal2-confirm{
  background-color: #93c572 !important;
}
.swal2-popup{
  border: thick double #93c572 !important;
}

.title-main{
  font-family: Roboto !important;
}
</style>
