<template>
  <div>
    <v-btn icon>
        <v-icon color="white" v-if="!$vuetify.theme.dark == true" @click="change">mdi-weather-sunny-alert</v-icon>
        <v-icon  v-else @click="change">mdi-theme-light-dark</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
    methods:{
        change(){
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        }
    }
}
</script>

<style>

</style>