import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {defaultDarkTheme, lightTheme, darkTheme} from './../config/config';

Vue.use(Vuetify);

import es from 'vuetify/lib/locale/es'

export default new Vuetify({
  rtl: false,
  theme: {
    dark: defaultDarkTheme,
    themes: {
      light: lightTheme,
      dark: darkTheme
    },
  },
  lang:{
    locales: {
      es
    },
    current: 'es'
  }
});
