import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import IsAuth from '@/store/modules/IsAuth';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/remember',
    name: 'remember',
    component: () => import('../views/Logout.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/ClientsApp'),
    meta: { requiresAuth: true}
  },
  {
    path: '/clients-companies',
    name: 'clientsandcompanies',
    component: () => import('../views/ClientsAndCompanies.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/roles-priv',
    name: 'roleandpriv',
    component: () => import('../views/RoleAndPriv.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/crops',
    name: 'crops',
    component: () => import('../views/Crop.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('../views/Devices.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('../views/Configuration.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/pp',
    name: 'pp',
    component: () => import('../views/ProductionCycle.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/supply',
    name: 'supply',
    component: () => import('../views/Supply.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/Download.vue'),
    meta: { requiresAuth: true }
  },
  ////////////////////////////////////////////////////////////////
  {
    path: '/dataview',
    name: 'dataview',
    component: () => import('../views/DataView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dataviewId/:id',
    name: 'dataviewIdCode',
    component: () => import('../views/DataViewIdCode.vue'),
    meta: { requiresAuth: true }
  },
  ////////////////////////////////
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/Blog.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/Support.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import('../views/Alerts.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/Reports.vue'),
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authenticatedUser = IsAuth.auth();
  
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && ! authenticatedUser){
    next('/');
  } 
  else next();
});

export default router
